<template>
    <div>
        <h1><b>Instructions :</b></h1>
            <ul>
                <li>Listen to voice A and B, and select the voice that you think has less distortion.</li>
                <li>Possible choices are "Definitely A", "Maybe A", "Maybe B", and "Definitely B".</li>              
                <li> The all tasks habe a total of <b>30 questions</b>.(Estimated completion time : 15 [min])</li>
            </ul>

        <p class="mb5"></p>
        <p></p>

        <h1><b>Note :</b></h1>
            <ul>
                <li>You cannot move onto the next question until you listen to both voice A and B at least once for each.</li>
                <li>While one voice is playing, the other voice cannot be played.</li>
                <li>You cannot choose "which is the better sound quality" while the voice is playing.</li>
                
                <li>In case <font color="red">any technical issue</font> occurs (which prevents you to move forward), <b>provide details and click the button to skip</b>.</li>
            </ul>

        <p class="mb5"></p>
        <p></p>
        
        <p>If you have questions, contact us at mturk04@pcl.cs.waseda.ac.jp.</p>
    </div>

</template>

<script>
import nanoMixIn from "@/mixins/nano";
export default {
    mixins: [nanoMixIn],
    data: () => ({//変数の定義=>View変える変数はここにかかないといけない
        defaultNanoProps: {
            "id_0": "/static/LJSpeech-1.1/LJ001-0001.wav",
            "id_1": "/static/LJSpeech-1.1/LJ001-0003.wav",
            "id_2": "/static/LJSpeech-1.1/LJ001-0005.wav",
        },
        audio: {
            A: {
                obj: new Audio(),
                playing: false,
                canPlay: false,
                playedOnce: 0
            },
            B: {
                obj: new Audio(),
                playing: false,
                canPlay: false,
                playedOnce: 0
            },
            C: {
                obj: new Audio(),
                playing: false,
                canPlay: false,
                playedOnce: 0
            },
        },
        audioLabels: ["A", "B", "C"],
    checkBox: {
          ERR: false,
        },
    }),
    computed: {
        allPlayedOnce() {
            try {
                var ret = true;
                for(var i in this.audioLabels) ret = ret && this.audio[this.audioLabels[i]].playedOnce;
                return ret;
            } catch {
                return false;
            }
        }
    },
    methods: {
        toggleBtn(label){
            var audio = this.audio[label];
            audio.playedOnce = 1;
            console.log(this.allPlayedOnce);

            if( !audio.obj.paused ){
                this.nano.ans[`start_time${label}`]["Stop"].push(Math.floor( new Date().getTime() / 1000 ));
                audio.obj.currentTime = 0;
                audio.obj.pause();
            }
            else{
                this.nano.ans[`start_time${label}`]["Start"].push(Math.floor( new Date().getTime() / 1000 ));
                audio.obj.play();
            }
        },
        togglePlaying(label){
            var audio = this.audio[label];
            if (audio.playing == true) {
                audio.playing = false;
            } else {
                audio.playing = true;
            }
            this.toggleBtn(label);
        },
        initAudio() {
            for(var i in this.audioLabels){
                let label = this.audioLabels[i];

                this.$set(this.audio, label, {
                    obj: new Audio(),
                    playing: false,
                    canPlay: false,
                    playedOnce: 0
                });

                let audio = this.audio[label];

                audio.obj.addEventListener("canplaythrough", () => {
                    audio.canPlay = true;
                })

                audio.obj.src = this.nano.props[`id_${i}`];
                this.nano.ans[`start_time${label}`] = {"Start": [], "Stop": []};

                audio.obj.addEventListener("ended", ()=>{
                        this.nano.ans[`start_time${label}`]["Stop"].push(Math.floor( new Date().getTime() / 1000 ));
                        audio.obj.currentTime = 0;
                        audio.playing = false;
                });

            }

        }
    },
    watch: {
        "nano.props"() {
            this.initAudio();
        }
    },
};
</script>

<style scoped>
p.mb5 {
    margin-bottom: 5em;
}
#passed_time_box {
  border: 2px solid #f00;
  background: #fdd;
  width: 30%;
  font-weight: bold;
  margin: 10px;
  padding: 5px;
  font-size: 1.5em;
}
.play-btn {
  width: 100px;
  height: 40px;
  font-size: 1.5em;
  font-weight: bold;
}
table{
  margin: 10px;
  width: 800px;
}
</style>