<template>
    <div class="card">
    <br />
    <h3>Compare Voice A and Voice B, and select the more natural voice.</h3>
    <p>Once you have selected, you will be automatically moved to the next question.</p>

    <div class="number">
        <center>
            <b>{{nano.props.num}}</b>
        </center>
    </div>

    <center>
    <object :data="nano.props.text" width="700" height="100" type="text/html" />
        <ul class="table">
          <li>
            <p><b>Voice A</b></p>
            <v-btn elevation="2" class="play-btn" :disabled="!audio.A.canPlay || audio.B.playing" @click="togglePlaying('A');">
              <v-icon v-if="!audio.A.canPlay">mdi-cached</v-icon>
              <v-icon v-else-if="!audio.A.playing">mdi-play</v-icon>
              <v-icon v-else>mdi-stop</v-icon>
            </v-btn>
          </li>
          <li><v-icon x-large>mdi-arrow-left-right-bold</v-icon></li>
          <li>
            <p><b>Voice B</b></p>
            <v-btn elevation="2" class="play-btn" :disabled="!audio.B.canPlay || audio.A.playing" @click="togglePlaying('B');">
              <v-icon v-if="!audio.B.canPlay">mdi-cached</v-icon>
              <v-icon v-else-if="!audio.B.playing">mdi-play</v-icon>
              <v-icon v-else>mdi-stop</v-icon>
            </v-btn>
          </li>
        </ul>
        <br />

        <div class="ansbtn">
              <v-btn elevation="2" height="50" width="130" color="#32cd32" :disabled="!allPlayedOnce || audio.A.playing || audio.B.playing" @click="nano.ans.choice='Definitely A';  nano.ans.submit_time=Math.floor( new Date().getTime() / 1000 ); submit()">Definitely A</v-btn>
              <v-btn elevation="2" height="50" width="130" color="#90ee90" :disabled="!allPlayedOnce || audio.A.playing || audio.B.playing" @click="nano.ans.choice='Maybe A';  nano.ans.submit_time=Math.floor( new Date().getTime() / 1000 ); submit()">Maybe A</v-btn>
              <v-btn elevation="2" height="50" width="130" color="#afeeee" :disabled="!allPlayedOnce || audio.A.playing || audio.B.playing" @click="nano.ans.choice='Maybe B';  nano.ans.submit_time=Math.floor( new Date().getTime() / 1000 ); submit()">Maybe B</v-btn>
              <v-btn elevation="2" height="50" width="130" color="#00bfff" :disabled="!allPlayedOnce || audio.A.playing || audio.B.playing" @click="nano.ans.choice='Definitely B';  nano.ans.submit_time=Math.floor( new Date().getTime() / 1000 ); submit()">Definitely B</v-btn>
        </div>

    </center>

        <p class="mb5"></p>
        <p></p>
        
        <div class="block">
        <center>
        <p>Can't play audio or have any problems?</p>
        <input type="text" v-nano placeholder="Please let us know details..."  v-model="problem" />
        </center>

        <br />
        
        <div class="skip-btn-wrapper">
            <button :disabled="problem == null" @click="submit()">CLICK HERE TO SKIP (※ONLY WHEN YOU FACE ANY PROBLEMS)</button>
        </div>
        </div>
    </div>
</template>

<script>
//変数変えるだけで、Viewが変わる。
import nanoMixIn from "@/mixins/nano";
export default {
    mixins: [nanoMixIn],
    data: () => ({//変数の定義=>View変える変数はここにかかないといけない
        problem: null,
        defaultNanoProps: {
            "text": "/static/scripts-html/30024.html",
            "id_0": "/static/wavs_v2/N16_VCC2TF1_VCC2SM3_30024_SPO.wav",
            "id_1": "/static/wavs_v2/N15_VCC2TM2_VCC2SF1_30024_HUB.wav"
        },
        audio: {
            A: {
                obj: new Audio(),
                playing: false,
                canPlay: false,
                playedOnce: 0
            },
            B: {
                obj: new Audio(),
                playing: false,
                canPlay: false,
                playedOnce: 0
            },
        },
        audioLabels: ["A", "B"],
    }),
    computed: {
        allPlayedOnce() {
            try {
                var ret = true;
                for(var i in this.audioLabels) ret = ret && this.audio[this.audioLabels[i]].playedOnce;
                return ret;
            } catch {
                return false;
            }
        }
    },
    methods: {
        toggleBtn(label){
            var audio = this.audio[label];
            audio.playedOnce = 1;
            console.log(this.allPlayedOnce);

            if( !audio.obj.paused ){
                this.nano.ans[`start_time${label}`]["Stop"].push(Math.floor( new Date().getTime() / 1000 ));
                audio.obj.currentTime = 0;
                audio.obj.pause();
            }
            else{
                this.nano.ans[`start_time${label}`]["Start"].push(Math.floor( new Date().getTime() / 1000 ));
                audio.obj.play();
            }
        },
        togglePlaying(label){
            var audio = this.audio[label];
            if (audio.playing == true) {
                audio.playing = false;
            } else {
                audio.playing = true;
            }
            this.toggleBtn(label);
        },
        initAudio() {
            for(var i in this.audioLabels){
                let label = this.audioLabels[i];

                this.$set(this.audio, label, {
                    obj: new Audio(),
                    playing: false,
                    canPlay: false,
                    playedOnce: 0
                });

                let audio = this.audio[label];

                audio.obj.addEventListener("canplaythrough", () => {
                    audio.canPlay = true;
                })

                audio.obj.src = this.nano.props[`id_${i}`];
                this.nano.ans[`start_time${label}`] = {"Start": [], "Stop": []};

                audio.obj.addEventListener("ended", ()=>{
                        this.nano.ans[`start_time${label}`]["Stop"].push(Math.floor( new Date().getTime() / 1000 ));
                        audio.obj.currentTime = 0;
                        audio.playing = false;
                });

            }

        }
    },
    watch: {
        "nano.props"() {
            this.initAudio();
        }
    },
};
</script>

<style scoped>
.card {
    width: 800px;
    padding: 20px;
    box-sizing: border-box;
    margin: 100px auto;
    border: 1px solid black;
}
div.block {
    padding: 15px 0;
}
.table li {
  display: table-cell;
  background-color: #ffffff;
  color: #000000;
  border: none;
  padding: 15px;
  vertical-align:middle;
}
input[type=text] {
    width: 40%;
    border: 1px solid grey;
    font-size: 1.2em;
    padding: 3px 10px;
    border-radius: 3px;
    box-sizing: border-box;
}
div.skip-btn-wrapper {
    padding: 10px 0;
    text-align: center;
}
div.skip-btn-wrapper>button {
    width: 550px;
    padding: 10px;
    color: #FFF;
    background-color: #4a4a4a;
    border-radius: 5px;
}
div.skip-btn-wrapper>button:disabled {
    color: #FFF;
    background-color: grey;
}
p.mb5 {
    margin-bottom: 5em;
}
#passed_time_box {
  border: 2px solid #f00;
  background: #fdd;
  width: 30%;
  font-weight: bold;
  margin: 10px;
  padding: 5px;
  font-size: 1.5em;
}
.play-btn {
  width: 100px;
  height: 40px;
  font-size: 1.5em;
  font-weight: bold;
}
.ansbtn{
  display: flex;
  justify-content:space-around;
  padding: 5px;
}
.number{
    padding: 30px;
    font-size: 1.5em;
}
</style>
