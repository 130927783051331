<template>
    <div class="card">
        <div class="block">
            <b>Pre-survey.</b> Please answer the following questions first.
        </div>

        <div class="block">
            <b>Q1.</b> Tell your gender.<br />
            <div class="flex">
                <v-radio-group v-nano.required v-model="gender" row>
                    <div class="check-item"><v-radio label="Male" value="Male"/></div>
                    <div class="check-item"><v-radio label="Female" value="Female"/></div>
                    <div class="check-item"><v-radio label="Other" value="Other"/></div>
                </v-radio-group>
            </div>
        </div>
                
        <div class="block">
            <b>Q2.</b> Tell your age. (Please input a single-byte number.)<br />
            <input type="number" min="0" max="120" v-nano.required v-model="agetext" />
        </div>

        <div class="next-btn-wrapper">
            <button :disabled="!canSubmit" @click="canSubmit ? submit() : false">NEXT</button>
        </div>
    </div>
</template>

<script>
import nanoMixIn from "@/mixins/nano";
export default {
    mixins: [nanoMixIn],
    data: () => ({
        gender: "",
        age: "",
        agetext: "",
    })
};

</script>

<style scoped>
.card {
    width: 800px;
    margin: 100px auto;
    border: 1px solid black;
    padding: 20px;
    box-sizing: border-box;
}
div.block {
    padding: 15px 0;
}
input[type=number] {
    width: 20%;
    border: 1px solid grey;
    font-size: 1.2em;
    padding: 3px 10px;
    border-radius: 3px;
    box-sizing: border-box;
}
input[type=text] {
    width: 300%;
    border: 1px solid grey;
    font-size: 1.2em;
    padding: 3px 10px;
    border-radius: 3px;
    box-sizing: border-box;
}
.flex {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
    justify-content: left;
    -webkit-align-items: center;
    align-items: center;
    white-space: nowrap;
}
.check-item {
    width: 200px;
    padding: 10px;
    display: inline!important;
}
.check-item>input {
    margin-right: 5px;
}
.check-item2 {
    width: 320px;
    padding: 10px;
}
.check-item2>input {
    margin-right: 5px;
}
div.next-btn-wrapper {
    padding: 10px 0;
    text-align: right;
}
div.next-btn-wrapper>button {
    width: 100px;
    padding: 10px;
    background-color: #eee;
    border-radius: 5px;
}
div.next-btn-wrapper>button:disabled {
    color: grey;
}
</style>